:deep(.ant-spin-nested-loading) {
  padding: 0 0.4rem;
}

.model .table {
  border-top: 0;
}

.th1 {
  text-align: left !important;
  padding-left: 0.3rem;
}

.center {
  text-align: center;
}